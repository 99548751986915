<template>
  <!--begin::Card-->
  <b-card
    class="card-custom"
    data-type="custom"
    header-class="card-header-tabs-line nav-tabs-line-3x"
  >
    <template #header>
      <!--begin::Card header tabs-->
      <div class="card-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
          <template v-for="(items, index) in filteredAdminUserDetailsNavTabs">
            <!--begin::Item-->
            <li :key="index" class="nav-item mr-3">
              <a
                :class="{ active: activeTabs == items.id }"
                class="nav-link pointer"
                data-toggle="tab"
                @click="activeTabs = items.id"
              >
                <span class="nav-icon">
                  <i :class="`${items.icon}`"></i>
                </span>
                <span class="nav-text text-capitalize">{{ items.name }}</span>
              </a>
            </li>
            <!--end::Item-->
          </template>
        </ul>
      </div>
      <!--end::Card header tabs-->
    </template>

    <!--begin::Card body-->
    <form class="form" id="kt_form">
      <div class="tab-content">
        <!--begin::Tab 1-->
        <div
          :class="{ active: activeTabs == 1 }"
          class="tab-pane px-7"
          role="tabpanel"
        >
          <b-row class="justify-content-center">
            <b-col md="9" lg="8" xl="7" class="my-2">
              <!--begin::Tab Title-->
              <b-row>
                <b-col cols="12" sm="3"></b-col>
                <b-col cols="12" sm="9">
                  <h6 class="text-dark font-weight-bold mb-10 text-capitalize">
                    {{ $t('label.userInfo') }}:
                  </h6>
                </b-col>
              </b-row>
              <!--end::Tab Title-->

              <b-form>
                <!--begin::Name Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.name')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.name.$model"
                        :state="validateAdminUserDetailsState('name')"
                        type="text"
                        :disabled="
                          !checkAccessRight(moduleName, [
                            permissionAction.ADMIN_USER_UPDATE
                          ]) || adminUserDetails.username == global.SUPER_ADMIN
                        "
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::Name Field-->

                <!--begin::Username Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.username')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.username.$model"
                        :state="validateAdminUserDetailsState('username')"
                        type="text"
                        class="form-control form-control-solid"
                        :disabled="
                          !checkAccessRight(moduleName, [
                            permissionAction.ADMIN_USER_UPDATE
                          ]) || adminUserDetails.username == global.SUPER_ADMIN
                        "
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.adminUserDetails.username.alphaNum"
                      >
                        {{ $t('fieldErrorMessage.invalidUsernameFormat') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-else-if="
                          !$v.adminUserDetails.username.minLength ||
                          !$v.adminUserDetails.username.maxLength
                        "
                      >
                        {{
                          $t('fieldErrorMessage.requiredMinMaxLength', [
                            '6',
                            '11'
                          ])
                        }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::Username Field-->

                <!--begin::Role Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.role')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group
                      v-if="adminUserDetails.username != global.SUPER_ADMIN"
                      class="form-group"
                    >
                      <b-form-select
                        v-model="$v.adminUserDetails.roleId.$model"
                        :state="validateAdminUserDetailsState('roleId')"
                        :options="constantsData.adminRole"
                        class="form-control form-control-solid"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                        :disabled="
                          !checkAccessRight(moduleName, [
                            permissionAction.ADMIN_USER_UPDATE
                          ])
                        "
                      ></b-form-select>
                      <b-form-invalid-feedback>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group v-else>
                      <b-form-input
                        v-model="adminUserDetails.role"
                        type="text"
                        size="lg"
                        :disabled="true"
                        class="form-control form-control-solid"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::Role Field-->

                <!--begin::Email Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.email')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserDetails.email.$model"
                        :state="validateAdminUserDetailsState('email')"
                        type="text"
                        :disabled="
                          !checkAccessRight(moduleName, [
                            permissionAction.ADMIN_USER_UPDATE
                          ]) || adminUserDetails.username == global.SUPER_ADMIN
                        "
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="!$v.adminUserDetails.email.email"
                      >
                        {{ $t('fieldErrorMessage.invalidEmailFormat') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::Email Field-->

                <!--begin::Status Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.status')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <AppActivationStatusLabel
                      :value="adminUserDetails.isActive"
                      :modifiable="true"
                      :isBuildInAction="true"
                      :id="Number(id)"
                      :statusLabelActionsObj="setActiveInactiveActionsObj"
                      :disabled="
                        !checkAccessRight(moduleName, [
                          permissionAction.ADMIN_USER_UPDATE
                        ]) || adminUserDetails.username == global.SUPER_ADMIN
                      "
                    ></AppActivationStatusLabel>
                  </b-col>
                </b-row>
                <!--end::Status Field-->

                <!--begin::Save Changes Button-->
                <b-row
                  v-if="
                    checkAccessRight(moduleName, [
                      permissionAction.ADMIN_USER_UPDATE
                    ]) && adminUserDetails.username != global.SUPER_ADMIN
                  "
                  class="mt-12"
                >
                  <b-col cols="12" sm="3"></b-col>
                  <b-col cols="12" sm="9">
                    <b-button
                      variant="light-primary"
                      class="font-weight-bold"
                      @click="updateInfo()"
                      >{{ $t('label.saveChanges') }}</b-button
                    >
                  </b-col>
                </b-row>
                <!--end::Save Changes Button-->
              </b-form>
            </b-col>
          </b-row>

          <div class="separator separator-dashed my-10"></div>

          <b-row class="justify-content-center">
            <b-col md="9" lg="8" xl="7" class="my-2">
              <!--begin::Created Date-->
              <b-row>
                <b-col cols="12" sm="3" class="text-md-right">
                  <label class="text-capitalize">{{
                    $t('label.createdDate')
                  }}</label>
                </b-col>
                <b-col cols="12" sm="9">
                  <label class="text-muted">{{
                    adminUserDetails.createdAt | dateTime
                  }}</label>
                </b-col>
              </b-row>
              <!--end::Created Date-->

              <!--begin::Last Update Date-->
              <b-row>
                <b-col cols="12" sm="3" class="text-md-right">
                  <label class="text-capitalize">{{
                    $t('label.lastUpdateDate')
                  }}</label>
                </b-col>
                <b-col cols="12" sm="9">
                  <label class="text-muted">{{
                    adminUserDetails.updatedAt | dateTime
                  }}</label>
                </b-col>
              </b-row>
              <!--end::Last Update Date-->
            </b-col>
          </b-row>
        </div>
        <!--end::Tab 1-->

        <!--begin::Tab 2-->
        <div
          :class="{ active: activeTabs == 2 }"
          class="tab-pane show px-7"
          role="tabpanel"
        >
          <b-row class="justify-content-center">
            <b-col md="9" lg="8" xl="7" class="my-2">
              <!--begin::Tab Title-->
              <b-row>
                <b-col cols="12" sm="3"></b-col>
                <b-col cols="12" sm="9">
                  <h6 class="text-dark font-weight-bold mb-10 text-capitalize">
                    {{ $t('label.changePassword') }}:
                  </h6>
                </b-col>
              </b-row>
              <!--end::Tab Title-->

              <b-form>
                <!--begin::New Password Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.newPassword')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserPassword.password.$model"
                        :state="validateAdminUserPasswordState('password')"
                        type="password"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="
                          !$v.adminUserPassword.password.minLength ||
                          !$v.adminUserPassword.password.maxLength
                        "
                      >
                        {{
                          $t('fieldErrorMessage.requiredMinMaxLength', [
                            '6',
                            '12'
                          ])
                        }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::New Password Field-->

                <!--begin::Confirm Password Field-->
                <b-row>
                  <b-col cols="12" sm="3" class="text-md-right">
                    <label class="text-capitalize">{{
                      $t('label.confirmPassword')
                    }}</label>
                  </b-col>
                  <b-col cols="12" sm="9">
                    <b-form-group class="form-group">
                      <b-form-input
                        v-model="$v.adminUserPassword.confirmPassword.$model"
                        :state="
                          validateAdminUserPasswordState('confirmPassword')
                        "
                        type="password"
                        class="form-control form-control-solid"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="
                          !$v.adminUserPassword.confirmPassword.sameAsPassword
                        "
                      >
                        {{ $t('fieldErrorMessage.invalidConfirmPassword') }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-else>
                        {{ $t('fieldErrorMessage.requiredFieldDefault') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!--end::Confirm Password Field-->
              </b-form>
            </b-col>
          </b-row>

          <!--begin::Save Changes Button-->
          <b-row
            v-if="
              checkAccessRight(moduleName, [permissionAction.ADMIN_USER_UPDATE])
            "
            class="justify-content-center"
          >
            <b-col md="9" lg="8" xl="7" class="my-2">
              <b-row>
                <b-col cols="12" sm="3"></b-col>
                <b-col cols="12" sm="9">
                  <b-button
                    variant="light-primary"
                    class="font-weight-bold"
                    @click="updatePassword()"
                    >{{ $t('label.saveChanges') }}</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--end::Save Changes Button-->
        </div>
        <!--end::Tab 2-->

        <div
          :class="{ active: activeTabs == 3 }"
          class="tab-pane show px-7"
          role="tabpanel"
        >
          <AuditLogsCard
            ref="auditLogsCard"
            :tableId="id"
            :logType="auditLogType"
          ></AuditLogsCard>
        </div>
      </div>
    </form>
    <!--begin::Card body-->
  </b-card>
  <!--end::Card-->
</template>

<script>
  import AuditLogsCard from '@/views/components/audit-logs/AuditLogsCard';
  import AppActivationStatusLabel from '@/views/components/bases/AppActivationStatusLabel';
  import KTUtil from '@/assets/js/components/util';
  import constantsMixin from '@/core/mixins/constants.mixin';
  import commonMixin from '@/core/mixins/common.mixin';
  import { validationMixin } from 'vuelidate';
  import { MODULE, ACTION, ROUTE_NAME, GLOBAL } from '@/core/constants';
  import { AUDIT_LOG_TABLE_NAME } from '@/core/constants/enums';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    required,
    email,
    minLength,
    maxLength,
    sameAs,
    alphaNum
  } from 'vuelidate/lib/validators';
  import {
    ADMIN_USER_GET_ADMIN_USER,
    ADMIN_USER_UPDATE_ADMIN_USER,
    ADMIN_USER_SET_ACTIVE_INACTIVE,
    ADMIN_USER_INITIAL_GET_ADMIN_USER_STATE,
    ADMIN_USER_INITIAL_UPDATE_ADMIN_USER_STATE,
    ADMIN_USER_INITIAL_SET_ACTIVE_INACTIVE_STATE
  } from '@/core/store/admin-user.module';

  export default {
    name: 'AccountManagementDetails',
    mixins: [commonMixin, constantsMixin, validationMixin],
    components: {
      AppActivationStatusLabel,
      AuditLogsCard
    },
    data: () => ({
      id: null,
      moduleName: MODULE.ADMIN_USER,
      routeName: ROUTE_NAME,
      global: GLOBAL,
      permissionAction: ACTION,
      adminUserDetails: {
        id: '',
        name: '',
        username: '',
        email: '',
        createdAt: '',
        updatedAt: '',
        role: null,
        roleId: null,
        isActive: false
      },
      originAdminUserDetails: null,
      adminUserPassword: {
        password: '',
        confirmPassword: ''
      },
      activeTabs: 1,
      adminUserDetailsNavTabs: [
        {
          id: 1,
          name: i18nHelper.getMessage('label.info'),
          icon: 'flaticon2-user-1'
        },
        {
          id: 2,
          name: i18nHelper.getMessage('label.changePassword'),
          icon: 'flaticon2-protected'
        },
        {
          id: 3,
          name: i18nHelper.getMessage('label.logs'),
          icon: 'flaticon2-list-3'
        }
      ],
      setActiveInactiveActionsObj: {
        module: 'adminUser',
        state: 'setActiveInactive',
        actionSet: ADMIN_USER_SET_ACTIVE_INACTIVE,
        actionInitial: ADMIN_USER_INITIAL_SET_ACTIVE_INACTIVE_STATE,
        successAction: () => {},
        errorActions: () => {},
        title: i18nHelper.getMessage('label.updateAccount'),
        message: i18nHelper.getMessage('label.account')
      },
      auditLogType: AUDIT_LOG_TABLE_NAME.ADMIN_USERS
    }),
    computed: {
      adminUserComplete() {
        return this.$store.state.adminUser.adminUser.complete;
      },
      updateAdminUserComplete() {
        return this.$store.state.adminUser.updateAdminUser.complete;
      },
      filteredAdminUserDetailsNavTabs() {
        let list = [];

        if (
          !this.checkAccessRight(this.moduleName, [ACTION.ADMIN_USER_UPDATE]) ||
          this.adminUserDetails.username == GLOBAL.SUPER_ADMIN
        ) {
          list = this.adminUserDetailsNavTabs.filter((x) => x.id != 2);
        } else {
          list = this.adminUserDetailsNavTabs;
        }

        return list;
      },
      route() {
        return this.$route;
      }
    },
    watch: {
      adminUserComplete() {
        let response = this.$store.state.adminUser.adminUser;
        if (response.complete) {
          this.getAdminUserCompleteAction(response);
        }
      },
      updateAdminUserComplete() {
        let response = this.$store.state.adminUser.updateAdminUser;
        if (response.complete) {
          this.updateAdminUserCompleteAction(response);
        }
      },
      deleteAdminUserComplete() {
        let response = this.$store.state.adminUser.deleteAdminUser;
        if (response.complete) {
          this.deleteAdminUserCompleteAction(response);
        }
      },
      route() {
        uiHelper.reloadPage();
      }
    },
    created() {
      this.initialAdminUserDetails();
    },
    methods: {
      initAuditLogsCard() {
        this.$refs.auditLogsCard.initAuditLogsCard();
      },
      deleteAdminUserCompleteAction(response) {
        let title = i18nHelper.getMessage('label.deleteUser');
        let messages = response.message;
        let buttons = [];

        if (response.code == 200) {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialDeleteAdminUserState();
              this.$router.push({ name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST });
            }
          });
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
              this.initialDeleteAdminUserState();
            }
          });
        }

        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      updateAdminUserCompleteAction(response) {
        let title = '';
        let messages = response.message;
        let buttons = [];

        title =
          this.activeTabs == 1
            ? i18nHelper.getMessage('label.updateUserInfo')
            : i18nHelper.getMessage('label.changePassword');

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.getAdminUser(this.id);
            this.closeLayoutAppModalInfo();
            this.initialUpdateAdminUserState();
            KTUtil.scrollTop();
          }
        });

        this.resetValidation();
        this.openLayoutAppModalInfo(title, messages, buttons);
      },
      getAdminUserCompleteAction(response) {
        let title = i18nHelper.getMessage('label.userDetails');
        let messages = response.message;
        let buttons = [];

        if (response.code == 200) {
          this.adminUserDetails = response.data;
          this.adminUserPassword = {
            password: '',
            confirmPassword: ''
          };
          this.originAdminUserDetails = this.adminUserDetails;
          this.activeTabs = 1;
        } else {
          buttons.push({
            text: i18nHelper.getMessage('label.close'),
            variant: 'primary',
            action: () => {
              this.closeLayoutAppModalInfo();
            }
          });
          this.openLayoutAppModalInfo(title, messages, buttons);
        }
        this.initialGetAdminUserState();
        this.resetValidation();
      },
      updateInfo() {
        let validation = this.validationAdminUserDetailsCheck();
        let isUpdatePassword = false;
        let d = this.adminUserDetails;
        let data = {
          id: d.id,
          name: d.name,
          username: d.username,
          email: d.email,
          roleId: d.roleId
        };

        if (validation) {
          this.updateAdminUser(data, isUpdatePassword);
        }
      },
      updatePassword() {
        let validation = this.validationAdminUserPasswordCheck();
        let isUpdatePassword = true;
        let d = this.originAdminUserDetails;
        let data = {
          id: d.id,
          name: d.name,
          username: d.username,
          email: d.email,
          roleId: d.roleId,
          password: this.adminUserPassword.password
        };

        if (validation) {
          this.updateAdminUser(data, isUpdatePassword);
        }
      },
      resetValidation() {
        this.$nextTick(() => {
          this.$v.$reset();
        });
      },
      validationAdminUserPasswordCheck() {
        this.$v.adminUserPassword.$touch();

        if (this.$v.adminUserPassword.$anyError) {
          return false;
        }

        return true;
      },
      validationAdminUserDetailsCheck() {
        this.$v.adminUserDetails.$touch();

        if (this.$v.adminUserDetails.$anyError) {
          return false;
        }

        return true;
      },
      validateAdminUserPasswordState(name) {
        const { $dirty, $error } = this.$v.adminUserPassword[name];
        return $dirty ? !$error : null;
      },
      validateAdminUserDetailsState(name) {
        const { $dirty, $error } = this.$v.adminUserDetails[name];
        return $dirty ? !$error : null;
      },
      getAdminUser(id) {
        this.$store.dispatch(ADMIN_USER_GET_ADMIN_USER, { id });
      },
      updateAdminUser(data, isUpdatePassword) {
        this.$store.dispatch(ADMIN_USER_UPDATE_ADMIN_USER, {
          data,
          isUpdatePassword
        });
      },
      initialGetAdminUserState() {
        this.$store.dispatch(ADMIN_USER_INITIAL_GET_ADMIN_USER_STATE);
      },
      initialUpdateAdminUserState() {
        this.$store.dispatch(ADMIN_USER_INITIAL_UPDATE_ADMIN_USER_STATE);
      },
      importSuccessActions() {
        this.setActiveInactiveActionsObj.successAction = () => {
          this.initAuditLogsCard();
          this.getAdminUser(this.id);
        };
      },
      initialAdminUserDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.accountManagement'),
              route: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT }
            },
            { title: i18nHelper.getMessage('label.accountManagementDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getAdminUser(id);
        this.initBreadCrumb(breadcrumbData);
        this.importSuccessActions();
      }
    },
    validations: {
      adminUserDetails: {
        name: {
          required
        },
        username: {
          required,
          alphaNum,
          minLength: minLength(6),
          maxLength: maxLength(11)
        },
        email: {
          required,
          email
        },
        roleId: {
          required
        }
      },
      adminUserPassword: {
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(12)
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('password')
        }
      }
    }
  };
</script>

<style></style>
